import React from "react";
import styles from "./intro.module.scss";
import salut from "assets/images/salut.svg";
import Button from "base/button/button";
import intro from "assets/images/intro.png";
import shape from "assets/images/shape/shape1.png";
import { TypeAnimation } from "react-type-animation";

export default function Intro() {
  return (
    <div className={styles["intro"]}>
      <div className={styles["intro__shape"]}>
        <img src={shape} alt="" />
      </div>
      <div className="auto__container">
        <div className={styles["intro__inner"]}>
          <div className={styles["intro__inner-bg"]}>
            <img src={intro} alt="intro" />
          </div>
          <div className={styles["intro__inner-content"]}>
            <h1>
              <TypeAnimation
                sequence={[`Meet`]}
                wrapper="strong"
                speed={10}
                omitDeletionAnimation={true}
                repeat={0}
                style={{ whiteSpace: "pre-line" }}
              />
              <TypeAnimation
                sequence={[500, ` Hermes`]}
                wrapper="span"
                speed={10}
                omitDeletionAnimation={true}
                repeat={0}
                style={{ whiteSpace: "pre-line" }}
              />
              {" "}
              <br />
              <TypeAnimation
                sequence={[1000, `by The Grit Co.`, (e) => {}]}
                wrapper="b"
                speed={10}
                omitDeletionAnimation={true}
                repeat={0}
                style={{ whiteSpace: "pre-line" }}
              />
              <i></i>
            </h1>
            <div className={styles["intro__inner-content-btn"]}>
              <button type="button">
                YOUR AI BUDDY <img src={salut} alt="salut" />
              </button>
            </div>

            <h3>Delivering a seamless recruiting experience</h3>
            <p>Coming Soon</p>
          </div>
          <div className={styles["intro__inner-act"]}>
            <a href="mailto:info@thegritco.io">
              <Button text={"Contact Us"} type={"primary"} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
