import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "assets/css/main.scss";
import Footer from "base/footer/footer";
import Header from "base/header/header";
import Home from "home/home";
import { AnimatePresence } from "framer-motion";
import Cookie from "base/cookie/cookie";
import Privacy from "privacy/privacy";
import Contact from "contact/contact";
export default function App() {
  const [cookie, setCookie] = useState(true);
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <main className="main">
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
      <AnimatePresence>
        {cookie &&
          (localStorage.getItem("cookie") === true ||
            localStorage.getItem("cookie") === null) && (
            <Cookie setCookie={setCookie} />
          )}
      </AnimatePresence>
    </>
  );
}
