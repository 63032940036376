import React from "react";
import styles from "./contact.module.scss";
export default function Contact() {
  return (
    <div className={styles["contact"]}>
      <div className="auto__container">
        <div className={styles["contact__inner"]}>
          <h3>Impressum</h3>
          <div className={styles["contact__item"]}>
            <h6>Company Name: </h6>
            <p>The Grit Company AG</p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Address: </h6>
            <p>Rennweg 57 8001 Zürich, Switzerland</p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Phone: </h6>
            <p>+41 78 234 49 18 </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Email: </h6>
            <p>
              <a href="mailto:info@thegritco.io">info@thegritco.io</a>
            </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Website: </h6>
            <p>
              <a href="http://thegritco.io">http://thegritco.io</a>
            </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Legal Representative: </h6>
            <p>Andras Lacko </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Registered Office: </h6>
            <p>Rennweg 57, 8001 Zürich, Switzerland </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>VAT Identification Number: </h6>
            <p>CHE-130.558.690 </p>
          </div>
          <div className={styles["contact__item"]}>
            <h6>Trade Register Number: </h6>
            <p>CH-020.4.072.336-0 </p>
          </div>
          <p className={styles["gray"]}>
            This imprint is provided in accordance with legal requirements and
            serves as information for the website visitors. Please direct any
            inquiries or concerns regarding this website and its content to the
            contact information provided above.
          </p>
        </div>
      </div>
    </div>
  );
}
