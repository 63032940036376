import React from "react";
import Intro from "./components/intro/intro";
import Features from "./components/features/features";
import Team from "./components/team/team";

export default function Home() {
  return (
    <>
      <div className="anchor" id="home"></div>
      <Intro />
      <div className="anchor" id="features"></div>
      <Features />
      <div className="anchor" id="team"></div>
      <Team />
    </>
  );
}
