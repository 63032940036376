import React from "react";
import styles from "./features.module.scss";
import shape from "assets/images/shape/shape2.png";
import { userCycle, userPlus, users } from "base/SVG";
const featureList = [
  {
    id: "1",
    icon: userPlus,
    text: "Helping you attract the right candidates through AI powered search.",
  },
  {
    id: "2",
    icon: userCycle,
    text: "Matching the best candidates made easy and correctly. Making sure nobody slips through!",
  },
  {
    id: "3",
    icon: users,
    text: "For those hard-to-find roles, leverage our network of vetted headhunters to streamline your search.",
  },
];
export default function Features() {
  return (
    <div className={styles["features"]}>
      <div className={styles["features__shape"]}>
        <img src={shape} alt="shape" />
      </div>
      <div className="auto__container">
        <div className={styles["features__inner"]}>
          {featureList.map((item, index) => {
            return <FeaturesItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const FeaturesItem = (props) => {
  return (
    <div className={styles["featuresItem"]}>
      <div className={styles["featuresItem__inner"]}>
        <div className={styles["featuresItem__icon"]}>{props.icon}</div>
        <h4>{props.text}</h4>
      </div>
    </div>
  );
};
