import React from "react";
import styles from "./footer.module.scss";
import logo from "assets/images/logo.svg";
import { linkedIn, mailIcon } from "base/SVG";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div className={styles["footer"]}>
      <div className="auto__container">
        <div className={styles["footer__inner"]}>
          <div className={styles["footer__inner-info"]}>
            <div className={styles["footer__inner-info-logo"]}>
              <img src={logo} alt="logo" />
            </div>{" "}
            <div className={styles["footer__inner-info-text"]}>
              <h6>The Grit Company AG</h6>
              <p>Rennweg 57, 8001 Zürich, Switzerland</p>
              <div className={styles["footer__inner-info-btns"]}>
                {" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/thegritco/"
                >
                  {linkedIn}
                </a>{" "}
                <a href="mailto:info@thegritco.io">{mailIcon}</a>
              </div>
            </div>
          </div>
          <div className={styles["footer__inner-more"]}>
            <p>CH-020.4.072.336-0</p>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/contact">Impressum</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
