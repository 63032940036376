import React from "react";
import styles from "./privacy.module.scss";
export default function Privacy() {
  return (
    <div className={styles["privacy"]}>
      <div className="auto__container">
        <div className={styles["privacy__inner"]}>
          <h3>
            <strong>Introduction</strong>
          </h3>
          <p>
            <strong>
              The Grit Company AG and its affiliates (&ldquo;We&ldquo;) are
              committed to protecting and respecting your privacy.
            </strong>
          </p>
          <p>
            <strong>
              This policy (together with our terms of use (the
              &ldquo;Terms&ldquo;) and any other documents referred to on it)
              sets out the basis on which any personal data we collect from you,
              or that you provide to us, will be processed by us. Please read
              the following carefully to understand our views and practices
              regarding your personal data and how we will treat it. By visiting
              and continuing to use&nbsp;
            </strong>
            <a href="http://thegritco.io/">
              <strong>http://thegritco.io</strong>
            </a>
            <strong>
              &nbsp;(&ldquo;Our Site&ldquo;) you are accepting and acknowledging
              the practices described in this policy.
            </strong>
          </p>
          <p>
            <strong>Information we may collect from you</strong>
          </p>
          <p>
            <strong>
              We may collect and process the following data about you:
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                Information you give us. You may give us information about you
                by filling in forms on Our Site, giving us a business card (or
                similar), visiting our premises or by corresponding with us by
                phone, e-mail or otherwise. This includes information you
                provide when you register to use Our Site or subscribe to our
                service and when you report a problem with Our Site. The
                information you give us may include your name, address, e-mail
                address and phone number; information about your business
                relationship with us; and information about your professional
                role, background and interests.
              </strong>
            </li>
            <li>
              <strong>
                Information we collect about you. With regard to each of your
                visits to Our Site we may automatically collect the following
                information:
              </strong>
            </li>
            <ul>
              <li>
                <strong>
                  technical information, including the Internet protocol (IP)
                  address used to connect your computer to the Internet, your
                  login information, browser type and version, time zone
                  setting, browser plug-in types and versions, operating system
                  and platform;
                </strong>
              </li>
              <li>
                <strong>
                  information about your visit, including the full Uniform
                  Resource Locators (URL) clickstream to, through and from Our
                  Site (including date and time); products you viewed or
                  searched for; page response times, download errors, length of
                  visits to certain pages, page interaction information (such as
                  scrolling, clicks, and mouse-overs), and methods used to
                  browse away from the page and any phone number used to call
                  our customer service number;
                </strong>
              </li>
              <li>
                <strong>
                  if you exchange emails, telephone conversations or other
                  electronic communications with our employees and other staff
                  members, our information technology systems will record
                  details of those conversations, sometimes including their
                  content;
                </strong>
              </li>
              <li>
                <strong>
                  Some of our premises have closed circuit TV systems which may
                  record you if you visit our premises, for security and safety
                  purposes
                </strong>
              </li>
            </ul>
            <li>
              <strong>
                Information we receive from other sources. We may receive
                information about you if you use any of the other websites we
                operate or the other services we provide. We are also working
                closely with third parties (including, for example, business
                partners, sub-contractors in technical, payment and delivery
                services, advertising networks, analytics providers, search
                information providers, credit reference agencies) and may
                receive information about you from them.
              </strong>
            </li>
          </ul>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            <strong>
              Our Site uses cookies to distinguish you from other users of Our
              Site. This helps us to provide you with a good experience when you
              browse Our Site and also allows us to improve Our Site.{" "}
            </strong>
          </p>
          <p>
            <strong>Uses made of the information</strong>
          </p>
          <p>
            <strong>
              We use information held about you in the following ways:
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                to carry out our obligations arising from any contracts entered
                into between you and us and to provide you with the information,
                products and services that you request from us;
              </strong>
            </li>
            <li>
              <strong>
                to operate, manage, develop and promote our business and, in
                particular, our relationship with you or the organisation you
                represent (if any) and related transactions;
              </strong>
            </li>
            <li>
              <strong>
                with your consent where required, to provide you with
                information about our goods and services which we believe you
                will find relevant and interesting;
              </strong>
            </li>
            <li>
              <strong>to notify you about changes to our service;</strong>
            </li>
            <li>
              <strong>
                to operate, administer and improve Our Site and premises and
                other aspects of the way in which we conduct our operations;
              </strong>
            </li>
            <li>
              <strong>
                to measure or understand the effectiveness of advertising we
                serve to you and others and to deliver relevant advertising to
                you;
              </strong>
            </li>
            <li>
              <strong>to protect the security of our premises;</strong>
            </li>
            <li>
              <strong>
                to protect our business from fraud, money-laundering, breach of
                confidence, theft of proprietary materials and other financial
                or business crimes;
              </strong>
            </li>
            <li>
              <strong>
                to comply with our legal and regulatory obligations and bring
                and defend legal claims.
              </strong>
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>
              We may combine this information with information you give to us
              and information we collect about you. We may use this information
              and the combined information for the purposes set out above
              (depending on the types of information we receive).
            </strong>
          </p>
          <p>
            <strong>
              We may from time to time review information about you held in our
              systems &ndash; including the contents of and other information
              related to your email and other communications with us &ndash; for
              compliance and business-protection purposes as described above.
              This may include reviews for the purposes of disclosure of
              information relevant to litigation and/or reviews of records
              relevant to internal or external regulatory or criminal
              investigations. To the extent permitted by applicable law these
              reviews will be conducted in a reasonable and proportionate way
              and approved at an appropriate level of management. They may
              ultimately involve disclosure of your information to governmental
              agencies and litigation counterparties as described below. Your
              emails and other communications may also occasionally be accessed
              by persons other than the member of staff with whom they are
              exchanged for ordinary business management purposes (for example,
              where necessary when a staff member is out of the office or has
              left us).
            </strong>
          </p>
          <p>
            <strong>
              We will only process your personal information as necessary so
              that we can pursue the purposes described above, and then only
              where we have concluded that our processing does not prejudice you
              or your privacy in a way that would override our legitimate
              interest in pursuing those purposes. In exceptional circumstances
              we may also be required by law to disclose or otherwise process
              your personal information. We will tell you, when we ask you to
              provide information about yourself, if provision of the requested
              information is necessary for compliance with a legal obligation
              or, on the other hand, if it is purely voluntary and there will be
              no implications if you decline to provide the information.
              Otherwise you should assume that we need the information for our
              business or compliance purposes (as described above). If you are
              uncertain as to our need for information that we request from you,
              please contact the representative asking for the information, or
              Contact us (see below), with your query.
            </strong>
          </p>
          <p>
            <strong>Disclosure of your information</strong>
          </p>
          <p>
            <strong>
              We may share your personal information with any member of our
              group, which means our subsidiaries, our ultimate holding company
              and its subsidiaries.
            </strong>
          </p>
          <p>
            <strong>
              We may share your information with selected third parties
              including:
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                Business partners, suppliers and sub-contractors for the
                performance of any contract we enter into with them or you;
              </strong>
            </li>
            <li>
              <strong>
                Analytics and search engine providers that assist us in the
                improvement and optimisation of Our Site;
              </strong>
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>
              We may disclose your personal information to third parties:
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                In the event that we sell any business or assets, in which case
                we may disclose your personal data to the prospective buyer of
                such business or assets;
              </strong>
            </li>
            <li>
              <strong>
                If The Grit Company AG or substantially all of its assets are
                acquired by a third party, in which case personal data held by
                it about its customers will be one of the transferred assets;
              </strong>
            </li>
            <li>
              <strong>
                If we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our Terms and other agreements; or to protect
                the rights, property, or safety of The Grit Compan AG, our
                customers, or others. This includes exchanging information with
                other companies and organisations for the purposes of fraud
                protection and credit risk reduction.
              </strong>
            </li>
          </ul>
          <p>
            <strong>Where we store your personal data</strong>
          </p>
          <p>
            <strong>
              The data that we collect from you may be transferred to, and
              stored at, a destination outside the European Economic Area
              (&ldquo;EEA&rdquo;) or outside Switzerland, including countries
              which do not offer the same level of protection as Switzerland or
              EEA countries. We will ensure that any transfers are carried out
              in accordance with applicable law, including, where applicable,
              being governed by data transfer agreements, designed to ensure
              that your personal information is protected, on terms approved for
              this purpose by the European Commission.
            </strong>
          </p>
          <p>
            <strong>Retention and deletion</strong>
          </p>
          <p>
            <strong>
              We will delete the information that we hold about you when we no
              longer need it. Specific information about our record retention
              policies is available on request. Please Contact us (see below).
            </strong>
          </p>
          <p>
            <strong>
              Note that we may retain some limited information about you even
              when we know that you have left the organisation that you
              represent, so that we can maintain a continuous relationship with
              you if and when we are in contact with you again, representing a
              different organisation.
            </strong>
          </p>
          <p>
            <strong>Your rights</strong>
          </p>
          <p>
            <strong>
              Article 8 of the Swiss Federal Act on Data Protection
              (&ldquo;FADP&rdquo;) gives you the right to access information
              held about you. For exercising your right of access, please
              contact us under the e-mail address given below.
            </strong>
          </p>
          <p>
            <strong>Changes to our privacy policy</strong>
          </p>
          <p>
            <strong>
              Any changes we may make to our privacy policy in the future will
              be posted on this page. Please check back frequently to see any
              updates or changes to our privacy policy.
            </strong>
          </p>
          <p>
            <strong>Contact</strong>
          </p>
          <p>
            <strong>
              Questions, comments and requests regarding this privacy policy
              should be addressed to&nbsp;
            </strong>
            <a href="mailto:info@thegritco.io">
              <strong>info@thegritco.io</strong>
            </a>
            <strong>
              &nbsp;or sent to&nbsp;<em>The Grit Company AG,</em>&nbsp;
              <em>Rennweg 57 8001 Z&uuml;rich, Switzerland</em>
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}
