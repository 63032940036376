import React from "react";
import ReactDom from "react-dom";
import { motion } from "framer-motion";
import styles from "./cookie.module.scss";
import Button from "base/button/button";

export default function Cookie({ setCookie }) {
  return ReactDom.createPortal(
    <motion.div
      className={styles["cookie"]}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, y: 100 }}
    >
      <div className="auto__container">
        <div className={styles["cookie__inner"]}>
          <h5>This site uses cookies to enhance your experience. </h5>
          <div className={styles["cookie__inner-btns"]}>
            {" "}
            <Button
              type="gray"
              onClick={() => {
                setCookie(false);
                localStorage.setItem("cookie", false);
              }}
              text="Cancel"
            />
            <Button
              type="primary"
              onClick={() => {
                setCookie(false);
                localStorage.setItem("cookie", false);
              }}
              text="Accept"
            />
          </div>{" "}
        </div>
      </div>
    </motion.div>,
    document.getElementById("popups")
  );
}
