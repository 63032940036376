import React from "react";
import styles from "./team.module.scss";
import image1 from "assets/images/team/andras.png";
import image2 from "assets/images/team/chris.png";
import image3 from "assets/images/team/claudio.png";
import image4 from "assets/images/team/dorothee.png";
import image5 from "assets/images/team/daniel.png";
import { linkedIn } from "base/SVG";
import shape from "assets/images/shape/shape1.png";
const teamTop = [
  {
    id: "1",
    image: image1,
    name: "Andras Lacko",
    linkedin: "https://www.linkedin.com/in/andraslacko/",
    prof: "Co-Founder & CEO",
    list: [
      {
        id: "1",
        text: "Previous founder expertise",
      },
      {
        id: "2",
        text: "Diversity & Inclusion expert",
      },
      {
        id: "3",
        text: (
          <>
            +15 years experience across HR & Learning, <br /> sport, art FMCG
            Industries
          </>
        ),
      },
    ],
  },
  {
    id: "2",
    image: image2,
    name: "Christian Wehrli",
    linkedin: "https://www.linkedin.com/in/christian-wehrli-ab99aa97/",
    prof: "Co-Founder & CTO",
    list: [
      {
        id: "1",
        text: "Previous exec in medium sized software company",
      },
      {
        id: "2",
        text: "Built large tech team in previous startup.",
      },
      {
        id: "3",
        text: "+20 years experience in it architecture, services and engineering",
      },
    ],
  },
];
const teamBot = [
  {
    id: "1",
    image: image3,
    name: "Claudio Staub",
    linkedin: "https://www.linkedin.com/in/claudiostaub/",
    prof: "Founding Investor and BoD",
    list: [
      {
        id: "1",
        text: "Early Investor & BoD",
      },
      {
        id: "2",
        text: "Co-founder of CYBERA VC backed startup",
      },
    ],
  },
  {
    id: "2",
    image: image4,
    name: "Dorothee Roessigner",
    linkedin: "https://www.linkedin.com/in/dorotheeroessinger/",
    prof: "HR Advisor",
    list: [
      {
        id: "1",
        text: "Strategic HR Advisor",
      },
      {
        id: "2",
        text: "Regional Head of HR Bouygues Group",
      },
    ],
  },
  {
    id: "3",
    image: image5,
    name: "Daniel Heller",
    linkedin: "https://www.linkedin.com/in/danielheller-farner/",
    prof: "Founding Investor and BoD",
    list: [
      {
        id: "1",
        text: "Early Investor & BoD",
      },
      {
        id: "2",
        text: "Partner at Farner Consulting Head of Startup Desk",
      },
    ],
  },
];
export default function Team() {
  return (
    <div className={styles["team"]}>
      <div className={styles["team__shape"]}>
        <img src={shape} alt="" />
      </div>
      <div className="auto__container">
        <div className={styles["team__inner"]}>
          <h2>Our People</h2>
          <div className={styles["team__row"]}>
            {teamTop.map((item, index) => {
              return <TeamItem {...item} key={index} />;
            })}
          </div>{" "}
          <div className={styles["team__row"]}>
            {teamBot.map((item, index) => {
              return <TeamItem {...item} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
const TeamItem = (props) => {
  return (
    <div className={styles["teamItem"]}>
      <div className={styles["teamItem__image"]}>
        <img src={props.image} alt="" />
      </div>
      <div className={styles["teamItem__content"]}>
        <div className={styles["teamItem__content-head"]}>
          <h5>{props.name}</h5>
          <a target="_blank" rel="noopener noreferrer" href={props.linkedin}>
            {linkedIn}
          </a>
        </div>
        <div className={styles["teamItem__content-body"]}>
          <h6>{props.prof}</h6>
          <ul>
            {props.list.map((item, index) => {
              return <li key={index}>{item.text}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
