import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import logo from "assets/images/logo.svg";
import classNames from "classnames";
import { Link } from "react-router-dom";
export default function Header() {
  const [menu, setMenu] = useState(false);
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  return (
    <div className={styles["header"]}>
      <div className="auto__container">
        <div className={styles["header__inner"]}>
          <Link to="" className={styles["header__inner-logo"]}>
            <img src={logo} alt="logo" />
          </Link>
          <div
            onClick={close}
            className={classNames(styles["nav"], {
              [styles.active]: menu,
            })}
          >
            <div className={styles["nav__inner"]}>
              <div className={styles["nav__inner-links"]}>
                <a onClick={() => setMenu(false)} href="/#home">
                  Home
                </a>
                <a onClick={() => setMenu(false)} href="/#features">
                  Features
                </a>
                <a onClick={() => setMenu(false)} href="/#team">
                  Our people
                </a>
              </div>
            </div>
          </div>
          <div
            className={classNames(styles["burger"], {
              [styles.active]: menu,
            })}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
